@import "../../assets/styles/vars.scss";

table {
  tr {
    td {
      &:first-child {
        color: #a2a9b5;
        font-size: 15px;
      }
    }
  }
}

.workout-cards {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;

  .ui-card {
    margin-right: 20px;
    margin-bottom: 20px;

    .button-extendable {
      margin: auto auto 0;
      font-size: 14px;

      .be-menu-action {
        text-align: left;

        p {
          font-size: 13px;
          padding: 8px 16px;
        }
      }
    }
  }
}

.workout-columns {
  display: flex;

  .workouts-divider {
    width: 2px;
    background: #edf0f4;
    margin: 0 60px;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 1em;
    margin: 0 0 20px;
  }

  .no-data {
    color: #a2a9b5;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    line-height: 1em;
  }

  .workouts-grid {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;
  }

  .upcoming-workouts,
  .previous-workouts {
    flex: 0 0 375px;
  }

  .upcoming-workouts {
    .workout-card {
      width: 100%;

      &:first-child {
        border: 5px solid $primary;
        background: $primary;
        color: #fff;

        .workout-card-title {
          &:before {
            position: relative;
            content: "UP NEXT:";
            font-size: 12px;
            line-height: 1em;
            top: -10px;
          }

          small {
            color: #fff;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .workout-card {
    position: relative;
    transition: 0.2s all;
    position: relative;
    z-index: 2;
    padding: 24px 20px;
    border-radius: 4px;
    background: #fff;
    border: 5px solid $accentGrey;
    transition: 0.2s all;
    cursor: pointer;

    &.previous {
      opacity: 0.35;
    }

    &:hover {
      opacity: 1;
    }

    .workout-card-title {
      p {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        line-height: 1em;
      }

      small {
        color: #a2a9b5;
        font-weight: 500;
        font-size: 12px;
        margin: 0;
        line-height: 1em;
      }
    }

    .workout-with {
      font-size: 14px;
      margin: 15px 0 0;
      line-height: 1em;
    }
  }
}
