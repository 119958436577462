@import "../../assets/styles/vars.scss";

.profile-picture-holder {
  position: relative;
  width: fit-content;

  .current-profile-picture {
    position: relative;
    z-index: 1;
    height: 98px;
    width: 98px;
    background: $secondary;
    border-radius: 50%;
    overflow: hidden;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .navigation-profile-initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 42px;
      color: #fff;
      line-height: 1em;
      font-weight: 500;
    }

    .profile-image-upload-progress {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 42px;
      color: $primary;
      line-height: 1em;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.8);
    }
  }

  .upload-picture-toggle {
    position: absolute;
    z-index: 2;
    height: 30px;
    width: 30px;
    right: 0;
    bottom: 0;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all;
    transform: scale(1);
    transform-origin: center;
    cursor: pointer;

    &:active {
      transform: scale(0.8);
    }

    svg {
      width: 15px;
    }
  }
}