.add-new-goal {
  .input-group-column {
    &:first-of-type {
      position: relative;
      z-index: 1009;
      .ui-select-wrapper {
      }
    }

    &:last-of-type {
      position: relative;
      z-index: 1008;
      .ui-select-wrapper {
      }
    }
  }
}
