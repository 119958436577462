@import "../../../assets/styles/vars.scss";

.ui-button {
  position: relative;
  background: $primary;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: 0.2s all;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  padding: 10px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    color: #fff;
    background: darken($primary, 5%);
  }

  &.SUCCESS,
  &.POSITIVE {
    background: $positiveFG;

    &:hover {
      background: darken($positiveFG, 5%);
    }
  }

  &.ALERT,
  &.AMBER {
    background: $amberFG;

    &:hover {
      background: darken($amberFG, 5%);
    }
  }

  &.ERROR,
  &.NEGATIVE {
    background: $negativeFG;

    &:hover {
      background: darken($negativeFG, 5%);
    }
  }

  &.is-loading {
    pointer-events: none;
    background: $disabledBG !important;
    color: $disabledFG !important;
    border: none !important;
  }

  &.is-full-width {
    width: 100%;
  }

  &.is-small {
    padding: 10px 14px;
    font-size: 12px;
    line-height: 1em;

    &.has-icon {
      padding: 10px 12px;

      svg {
        height: auto;
        width: 11px;
      }
    }
  }

  &:disabled {
    opacity: 0.4;
  }

  .ui-button-icon {
    position: relative;
    height: 12px;
    width: 12px;
    margin-left: 8px;

    &.no-label {
      margin-left: 0;
    }

    svg {
      position: absolute;
      height: 110%;
      top: 0;
      left: 0;

      path {
        fill: #fff;
      }
    }
  }

  .ui-button-loading-icon {
    position: relative;
    height: 12px;
    width: 12px;
    margin-left: 8px;
    transform: rotate(10deg);
    animation-name: loadingSpinner;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

    svg {
      position: absolute;
      top: 0;
      right: 0;
      height: 78%;
    }
  }
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
