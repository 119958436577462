* {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
