@import "../../assets/styles/vars.scss";

.messages-modal {
  flex: 1 1 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 330px 1fr;

  .messages-list {
    height: 100%;
    display: flex;
    flex-direction: column;

    .chats {
      flex: 1 1 auto;
      overflow-y: auto;

      .chat-element {
        position: relative;
        height: 82px;
        transition: 0.2s all;
        border-bottom: 1px solid $accentGrey;
        cursor: pointer;
        overflow: hidden;

        &.unread {
          background: $backgroundGrey;
        }

        .chat-preview {
          position: absolute;
          z-index: 2;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          padding: 22px;
          background: #fff;
          transition: 0.2s all;
          display: flex;

          &.active {
            left: -60px;
          }

          .chat-text {
            .message-name {
              font-size: 16px;
              font-weight: 400;
              line-height: 1em;
              margin: 0 0 8px;
            }

            .message-preview {
              font-size: 12px;
              line-height: 1em;
              color: $subtitleGrey;
              margin: 0;
              max-width: 234px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              padding-bottom: 2px;
            }
          }

          .chat-edit {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;
          }
        }

        .chat-actions {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 1;
          height: calc(100% - 2px);
          margin: 1px 0;
          width: 60px;
          top: 0;
          right: 0;
          color: #fff;

          .chat-action-delete {
            height: 100%;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $red;
            transition: 0.2s all;

            &:hover {
              background: darken($red, 5%);
            }
          }

          .chat-action-mute {
            height: 100%;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $orange;
            transition: 0.2s all;

            &:hover {
              background: darken($orange, 5%);
            }
          }

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .new-message {
      background: $accentGreyLight;
      transition: 0.2s all;
      color: $primary;
      display: flex;
      align-items: center;
      padding: 16px 26px;
      margin-top: auto;
      flex: 0 0 auto;
      cursor: pointer;

      svg {
        height: 20px;
        margin-right: 8px;

        path {
          fill: $primary;
          transition: 0.2s all;
        }
      }

      p {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
      }

      &:hover {
        color: #fff;
        background: $primary;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  .message-window {
    height: 100%;
    background: $accentGrey;

    .chat-window-wrapper {
      height: 100%;
      position: relative;
      background: $accentGrey;
      display: flex;
      flex-direction: column;
      border-left: 2px solid $accentGrey;

      .chat-messages {
        position: relative;
        flex: 1 1 auto;
        overflow-y: auto;
        background: #fff;
        padding: 0 15px 15px;
        height: 100px;

        .start-of-conversation {
          font-size: 14px;
          color: $placeholderGreyLight;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          opacity: 0.6;
        }

        .message-element {
          position: relative;
          padding: 12px 20px;
          max-width: 320px;
          background: $primary;
          margin-top: 14px;
          border-radius: 2px;

          .setup-billing-method {
            text-align: center;
            margin: 12px auto 0 !important;
            font-size: 12px !important;
            font-weight: 500;
            max-width: 250px !important;

            a {
              color: $primary;
              font-weight: 500;

              &:hover {
                color: darken($primary, 5%);
              }
            }
          }

          &.price-card {
            display: flex;
            flex-direction: column;
            background: #fff;
            padding: 0;
            border-radius: 15px;
            overflow: hidden;

            &.this-user {
              .offer-summary {
                background: $secondary;
              }
            }

            .offer-summary {
              background: $primary;
              display: flex;
              flex-direction: column;
              padding: 20px;
              color: #fff;

              .price {
                font-size: 40px;
                font-weight: 600;
                margin: 0 0 2px;
                line-height: 1em;
              }

              .price-terms {
                font-size: 13px;
                font-weight: 300;
                font-style: italic;
                margin: 0;
                line-height: 1em;
              }
            }

            .offer-details {
              position: relative;
              display: flex;
              flex-direction: column;
              padding: 20px;
              background: #ecf0f1;
              border-top: 1px solid #fff;
              border-top-style: dashed;

              p {
                color: #1e2326;
                margin: 0;
                font-size: 14px;
                line-height: 1.3em;

                &.price-accepted {
                  font-size: 12px;
                  margin-top: 15px;
                  font-weight: 500;
                  line-height: 1.45em;
                }
              }

              .button {
                margin-top: 14px;
              }

              &:before,
              &:after {
                content: "";
                position: absolute;
                z-index: 10;
                top: -10px;
                background: #fff;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                left: -10px;
              }

              &:after {
                left: unset;
                right: -10px;
              }
            }
          }

          &.this-user {
            background: $secondary;
            margin-left: auto;
          }

          &.global-message {
            margin-left: auto;
            margin-right: auto;
            background: $backgroundGrey;
            width: fit-content;
            max-width: 100%;

            &.price-accepted {
              p {
                color: $placeholderGrey;
              }
            }

            &.payment-successful {
              background: $green02;
            }

            p {
              font-size: 13px;
              font-weight: 500;
              color: #fff;
              white-space: nowrap;
            }
          }

          p {
            color: #fff;
            margin: 0;
            font-size: 15px;
            line-height: 1.4em;
          }

          small {
            display: block;
            color: #fff;
            font-size: 12px;
            margin-top: 8px;
          }
        }
      }

      .chat-input {
        position: relative;
        flex: 0 0;
        margin-top: auto;
        padding: 8px;
        display: flex;
        align-items: center;

        .inputfield-wrapper {
          width: 100%;
        }

        .button {
          margin: 0;
        }

        .input-block {
          margin-bottom: 0;
          margin-right: 8px;
          flex: 1 1;

          input {
            padding: 8px 20px;
          }
        }
      }
    }
  }
}

.start-conversation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #fff;
  padding: 10px;
  height: 100%;
  border-left: 2px solid $accentGrey;

  .start-conversation-search {
    margin-bottom: 65px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin: 0 0 14px;
    }

    h2 {
      max-width: 475px;
      font-size: 15px;
      font-weight: 300;
      padding: 20px 55px 20px 30px;
      border-radius: 4px;
      line-height: 1.5em;
      margin: 0 0 20px;
      background: #f4f6f9;
    }

    .input-block {
      margin: 0 auto;
    }
  }

  .start-conversation-results {
    height: 245px;
    max-width: 455px;
    margin: 45px auto 0;
    overflow-y: auto;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .ais-Hits-item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .filtered-trainer {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 48px 1fr 96px;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 22px;
      }

      .filtered-trainer-image {
        position: relative;
        height: 48px;
        width: 48px;
        margin-right: 20px;
        flex: 0 0 48px;
        border-radius: 50%;
        overflow: hidden;
        background: $primary;

        p {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 20px;
          text-transform: uppercase;
        }

        img {
          position: absolute;
          z-index: 3;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      p {
        flex: 0 0 140px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1em;
        margin: 0 20px 0 0;
        text-align: left;

        span {
          display: block;
          line-height: 1em;
          margin-top: 5px;
          font-size: 12px;
          font-weight: 400;
          color: $subtitleGrey;
        }
      }

      button {
        font-size: 13px;
        margin: 0 !important;
        padding: 0 18px;
        height: 35px;
        flex: 0 0;
      }
    }
  }
}
