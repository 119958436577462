@import "../../assets/styles/vars.scss";

.meal-card {
  position: relative;

  &:hover {
    &:after {
      opacity: 1;
    }

    .meal-card-inner {
      border: 5px solid $primary;
    }
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $gradient;
    opacity: 0;
    transition: 0.2s all;
    filter: blur(25px);
  }

  .meal-card-inner {
    position: relative;
    z-index: 2;
    padding: 24px 20px;
    border-radius: 4px;
    background: #fff;
    border: 5px solid $accentGrey;
    transition: 0.2s all;

    .meal-picture {
      position: relative;
      height: 94px;
      width: 94px;
      border-radius: 50%;
      overflow: hidden;
      background: $secondary;
      margin: 0 auto 20px;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .meal-details {
      text-align: center;

      .meal-title {
        font-size: 20px;
        font-weight: 600;
        color: $placeholderGrey;
        margin: 0;
        line-height: 1em;
      }

      .meal-time {
        font-size: 12px;
        font-weight: 400;
        color: $subtitleGrey;
        margin: 10px 0 0;
        line-height: 1em;
      }
    }

    .meal-tags {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 20px;

      .meal-tag {
        color: $subtitleGrey;
        font-size: 10px;
        font-weight: 600;
        border: 2px solid $subtitleGrey;
        border-radius: 20px;
        padding: 3px 8px;
        line-height: 1em;
        margin-right: 5px;
        white-space: nowrap;
      }

      .meal-tag-more {
        font-size: 10px;
        font-weight: 600;
        color: #fff;
        background: $secondary;
        padding: 3px 8px;
        border-radius: 20px;
      }
    }

    .meal-summary {
      p {
        font-size: 13px;
        text-align: center;
        color: $placeholderGrey;
        margin: 20px 0;
        line-height: 1.45em;
      }
    }

    .button-extendable {
      margin: 0 auto;
      font-size: 13px;

      .be-toggle {
        svg {
          height: 5px;
        }
      }

      .be-menu {
        .be-menu-action {
          p {
            font-size: 13px;
            padding: 10px 18px;
          }
        }
      }
    }
  }
}

.meal-modal-column {
  max-height: 250px;
  overflow-y: auto;
  &:not(:last-of-type) {
    margin-bottom: 25px;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #4e5c70;
    margin: 0 0 8px;
  }

  .list-item {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .tags-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    p {
      position: relative;
      color: $subtitleGrey;
      font-size: 12px;
      font-weight: 500;
      border: 1px solid $subtitleGrey;
      border-radius: 20px;
      padding: 4px 12px;
      line-height: 1em;
      margin: 0 8px 8px 0;
      white-space: nowrap;
      user-select: none;

      span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        border-left: 1px solid $subtitleGrey;
        background: #fff;
        border-radius: 0 20px 20px 0;
        transition: 0.2s all;
        cursor: pointer;

        &:hover {
          color: #fff;
          background: $red;
        }
      }
    }
  }
}
