@import "../../assets/styles/vars.scss";

.metric-card {
  position: relative;
  max-width: 475px;

  &:not(:last-child) {
    margin-bottom: 45px;
  }

  &.no-data {
    .stat-details {
      filter: blur(6px);
      opacity: 0.45;
    }
  }

  .stat-no-data {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $placeholderGrey;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: center;
  }

  .stat-heading {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 10px;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    button {
      margin-left: 14px;
      padding: 10px 13px;
      height: auto;

      svg {
        height: 10px;
        width: auto;

        path {
          fill: #fff;
          opacity: 1;
        }
      }
    }
  }

  .stat-numbers {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 10px 0 25px;

    .big-stat {
      p {
        font-size: 41px;
        font-weight: 600;
        line-height: 48px;
        margin: 0;

        span {
          font-size: 20px;
        }

        small {
          color: $placeholderGrey;
          display: block;
          font-size: 11px;
          line-height: 2px;
          font-weight: 500;
        }
      }
    }

    .chart-legend {
      display: flex;
      align-items: flex-end;

      .legend-label {
        font-size: 26px;
        font-weight: 600;

        span {
          font-size: 14px;
        }

        small {
          display: block;
          font-size: 12px;
          font-weight: 500;
          line-height: 2px;
        }

        &:not(:last-child) {
          margin-right: 16px;
        }

        &.purple {
          color: $primary;
        }

        &.green {
          color: $green02;
        }

        &.blue {
          color: $blue02;
        }
      }
    }
  }

  .stat-chart {
    width: 475px;
    height: 240px;

    &.small {
      height: 145px;
    }
  }
}

.nutrition-page {
  display: flex;

  .nutrition-charts {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding-right: 100px;
    border-right: 2px solid $accentGrey;
  }

  .nutrition-additional {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-left: 100px;

    .rbc-time-header {
      border: none;
      padding-bottom: 5px;

      .rbc-time-header-content {
        border: none;

        .rbc-allday-cell {
          display: none;
        }
      }
    }

    .rbc-events-container {
      margin-right: 0;

      .rbc-event {
        padding: 2px 4px;
      }

      .rbc-event-content {
        font-size: 12px;
      }
    }

    .rbc-time-content {
      border-top: none;
    }

    .rbc-time-gutter {
      .rbc-timeslot-group {
        border: none;

        .rbc-label {
          position: relative;
          top: -4px;
          padding: 0 5px 0 0;
          line-height: 1em;
        }
      }
    }

    .rbc-day-slot {
      .rbc-timeslot-group {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .nutrition-page {
    .nutrition-charts {
      padding-right: 50px;
    }

    .nutrition-additional {
      padding-left: 50px;
    }
  }

  .stat-card {
    .stat-heading {
      font-size: 20px;
    }
  }
}
