@import "../../../assets/styles/vars.scss";

.website-contact {
  margin-top: 12px;
  display: grid;
  grid-gap: 0;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  min-height: 975px;

  &.no-top {
    margin-top: 0;
  }

  .wc-form {
    margin-left: 100px;
  }

  .wc-form-wrapper {
    margin-top: 50px;
    display: grid;
    grid-gap: 22px;
    grid-template-columns: 1fr;
    max-width: 550px;

    textarea,
    input {
      position: relative;
      width: 340px;
      font-size: 15px;
      color: #fff;
      transition: 0.2s all;
      outline: none !important;
      border-radius: 2px;
      padding: 10px 16px;
      border: 2px solid $secondary;
      resize: none;
      font-family: "Poppins", sans-serif;
      background: transparent;
      width: 100%;

      &::placeholder {
        font-size: 14px;
        color: #fff;
        transition: 0.2s all;
        opacity: 0.5;
      }

      &:focus,
      &:active {
        color: #fff;
        border: 2px solid #50bac8 !important;
        box-shadow: 0 0 2px #50bac8;
      }

      &:hover {
        color: #fff;
        border: 2px solid #e5e5e5;

        &::placeholder {
          color: #fff;
        }
      }
    }

    button {
      width: 100%;
    }
  }

  .wc-form-details {
    margin-top: 50px;

    .wc-form-contact {
      display: flex;
      align-items: center;

      a {
        font-size: 16px;
        color: #fff;
        text-decoration: none;
      }

      span {
        color: #fff;
        margin: 0 20px;
      }
    }

    .wc-form-socials {
      display: flex;
      align-items: center;
      margin-top: 25px;

      a {
        &:not(:last-child) {
          margin-right: 20px;
        }

        svg {
          height: 18px;
          width: auto;

          path {
            fill: #fff;
          }
        }
      }
    }
  }

  .image {
    position: relative;
    height: 100%;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.website-footer {
  background: $primary;
  padding: 15px;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    a {
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      text-decoration: none;
      margin: 0 45px;
    }
  }
}

.message-sent {
  color: #fff;
  margin: 0 0 10px;
}

.website-copyright {
  text-align: center;
  padding: 20px 14px;

  p {
    font-size: 14px;
    color: #fff;
    opacity: 0.6;
    line-height: 1em;
    margin: 0;
  }
}

@media (max-width: 1200px) {
  .website-contact {
    .wc-form-wrapper {
      input,
      textarea,
      button {
        width: 450px !important;
      }
    }
  }
}

@media (max-width: 850px) {
  .website-contact {
    grid-template-columns: 1fr;

    .wc-form {
      margin-left: 0 !important;
      padding: 0 20px;

      .heading-block {
        text-align: center;
      }

      .wc-form-wrapper {
        margin-top: 30px;
      }

      .wc-form-details {
        margin-top: 20px;
      }

      .wc-form-contact {
        justify-content: center;
      }

      .wc-form-socials {
        justify-content: center;
        text-align: center;

        a {
          &:not(:last-child) {
            margin-right: 30px;
          }

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .image {
      height: 300px;
    }
  }

  .website-footer {
    ul {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr;
      text-align: center;
    }
  }
}
