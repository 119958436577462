.client-cards-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1650px) {
  .client-cards-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
