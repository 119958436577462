@import "../../../assets/styles/vars.scss";

.client-equipment-list {
  margin: 12px 0;

  p {
    margin: 0;
    line-height: 1em;
    font-size: 15px;
    font-weight: 500;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.assign-meal-inputs {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr auto;
  margin-bottom: 16px;

  .input-block {
    input {
      padding: 8px 16px;
    }
  }

  .button {
    margin-top: 0 !important;
  }
}

.add-event-modal {
  margin-bottom: 14px;
  .button {
    margin-top: 0 !important;
  }
}

.client-modal-tab {
  &.summary {
    .remove-client {
      p {
        font-size: 14px;
        margin: 0 0 4px;

        &:last-child {
          font-size: 15px;
          font-weight: 500;
          color: $red;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-btn-group {
    button {
      font-size: 12px;
      padding: 4px 10px !important;
    }
  }

  .rbc-toolbar-label {
    font-size: 16px;
  }

  .rbc-time-header-content {
    border: none;
  }

  .rbc-events-container {
    margin-right: 0 !important;

    .rbc-event {
      padding: 2px 4px;
    }

    .rbc-event-content {
      font-size: 12px;
    }
  }

  .rbc-time-content {
    border-top: none;
  }

  .rbc-time-gutter {
    .rbc-timeslot-group {
      border: none;

      .rbc-label {
        position: relative;
        top: -4px;
        padding: 0 5px 0 0;
        line-height: 1em;
      }
    }
  }

  .rbc-day-slot {
    .rbc-timeslot-group {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.calorie-targets {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr auto;
}

.tab-workout-columns {
  display: grid;
  grid-template-columns: 265px auto 1fr auto 1fr;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 100%;
  min-height: 120px;

  .workouts-divider {
    width: 2px;
    background: #edf0f4;
    margin: 0 40px;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;
    margin: 0 0 10px;
  }

  .no-data {
    color: #a2a9b5;
    font-weight: 500;
    font-size: 13px;
    margin: 0;
    line-height: 1.45em;
  }

  .workouts-grid {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;
  }

  .upcoming-workouts,
  .previous-workouts {
    flex: 0 0 255px;
  }

  .upcoming-workouts {
    .workout-card {
      width: 100%;

      &:first-child {
        border: 5px solid $primary;
        background: $primary;
        color: #fff;

        .workout-card-title {
          &:before {
            position: relative;
            content: "UP NEXT:";
            font-size: 12px;
            line-height: 1em;
            top: -10px;
          }

          small {
            color: #fff;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .workout-card {
    position: relative;
    transition: 0.2s all;
    position: relative;
    z-index: 2;
    padding: 14px 20px;
    border-radius: 4px;
    background: #fff;
    border: 5px solid $accentGrey;
    transition: 0.2s all;

    &.previous {
      opacity: 0.35;
    }

    &:hover {
      opacity: 1;
    }

    .workout-card-title {
      p {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        line-height: 1em;
      }

      small {
        color: #a2a9b5;
        font-weight: 500;
        font-size: 12px;
        margin: 0;
        line-height: 1em;
      }
    }

    .workout-with {
      font-size: 14px;
      margin: 15px 0 0;
      line-height: 1em;
    }
  }
}

.parq-completed {
  font-size: 16px;
  font-weight: 500;
}

.client-parq-responses {
  max-width: 75%;

  tr {
    &:nth-child(odd) {
      background: $accentGrey;
    }

    td {
      padding: 5px 12px;

      &:first-child {
        color: #000;
      }
    }
  }
}

.parq-comments-title {
  font-size: 16px;
  font-weight: 500;
  margin: 40px 0 10px;
}

.parq-comments {
  margin: 0;
  font-size: 16px;
  line-height: 1.45em;
  max-width: 85%;
}

.client-messages {
  flex: 1 1;
  height: 100%;
  bottom: -24px;
  left: -24px;
  width: calc(100% + 48px);
  position: relative;

  .message-window {
    height: 100%;
    background: $accentGrey;

    .chat-window-wrapper {
      height: 100%;
      position: relative;
      background: $accentGrey;
      display: flex;
      flex-direction: column;
      border-left: 2px solid $accentGrey;

      .chat-messages {
        position: relative;
        flex: 1 1 auto;
        overflow-y: auto;
        background: #fff;
        padding: 0 15px 15px;
        height: 100px;

        .start-of-conversation {
          font-size: 14px;
          color: $placeholderGreyLight;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          opacity: 0.6;
        }

        .message-element {
          position: relative;
          padding: 12px 20px;
          max-width: 320px;
          background: $primary;
          margin-top: 14px;
          border-radius: 2px;

          .setup-billing-method {
            text-align: center;
            margin: 12px auto 0 !important;
            font-size: 12px !important;
            font-weight: 500;
            max-width: 250px !important;

            a {
              color: $primary;
              font-weight: 500;

              &:hover {
                color: darken($primary, 5%);
              }
            }
          }

          &.price-card {
            display: flex;
            flex-direction: column;
            background: #fff;
            padding: 0;
            border-radius: 15px;
            overflow: hidden;

            &.this-user {
              .offer-summary {
                background: $secondary;
              }
            }

            .offer-summary {
              background: $primary;
              display: flex;
              flex-direction: column;
              padding: 20px;
              color: #fff;

              .price {
                font-size: 40px;
                font-weight: 600;
                margin: 0 0 2px;
                line-height: 1em;
              }

              .price-terms {
                font-size: 13px;
                font-weight: 300;
                font-style: italic;
                margin: 0;
                line-height: 1em;
              }
            }

            .offer-details {
              position: relative;
              display: flex;
              flex-direction: column;
              padding: 20px;
              background: #ecf0f1;
              border-top: 1px solid #fff;
              border-top-style: dashed;

              p {
                color: #1e2326;
                margin: 0;
                font-size: 14px;
                line-height: 1.3em;

                &.price-accepted {
                  font-size: 12px;
                  margin-top: 15px;
                  font-weight: 500;
                  line-height: 1.45em;
                }
              }

              .button {
                margin-top: 14px;
              }

              &:before,
              &:after {
                content: "";
                position: absolute;
                z-index: 10;
                top: -10px;
                background: #fff;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                left: -10px;
              }

              &:after {
                left: unset;
                right: -10px;
              }
            }
          }

          &.this-user {
            background: $secondary;
            margin-left: auto;
          }

          &.global-message {
            margin-left: auto;
            margin-right: auto;
            background: $backgroundGrey;
            width: fit-content;
            max-width: 100%;

            &.price-accepted {
              p {
                color: $placeholderGrey;
              }
            }

            &.payment-successful {
              background: $green02;
            }

            p {
              font-size: 13px;
              font-weight: 500;
              color: #fff;
              white-space: nowrap;
            }
          }

          p {
            color: #fff;
            margin: 0;
            font-size: 15px;
            line-height: 1.4em;
          }

          small {
            display: block;
            color: #fff;
            font-size: 12px;
            margin-top: 8px;
          }
        }
      }

      .chat-input {
        position: relative;
        flex: 0 0;
        margin-top: auto;
        padding: 8px;
        display: flex;
        align-items: center;

        .inputfield-wrapper {
          width: 100%;
        }

        .button {
          margin: 0;
        }

        .input-block {
          margin-bottom: 0 !important;
          margin-right: 8px;
          flex: 1 1;

          input {
            padding: 8px 20px;
          }
        }
      }
    }
  }
}
