@import "../../../assets/styles/vars.scss";

.button-extendable {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 15px;
  font-weight: 500;
  height: 42px;
  width: fit-content;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  color: #fff;
  background: $primary;
  transition: 0.2s all;

  .be-main {
    padding: 0 22px;
    background: $primary;
    transition: 0.2s all;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;

    &:hover {
      background: darken($primary, 5%);
    }
  }

  .be-toggle {
    padding: 0 12px;
    border-left: 1px solid #fff;
    background: $primary;
    transition: 0.2s all;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 0 4px 4px 0;
    overflow: hidden;

    &:hover {
      background: darken($primary, 5%);
    }

    svg {
      height: 6px;
      width: auto;
    }
  }

  .be-menu {
    position: absolute;
    right: 0;
    bottom: calc(100%);
    box-shadow: 0 0 30px rgba(78, 78, 78, 0.2);
    background: #fff;
    border-radius: 2px;
    transition: 0.2s all;
    opacity: 0;
    border-radius: 2px;
    border: 4px solid #fff;
    overflow: hidden;
    pointer-events: none;

    &.is-active {
      bottom: calc(100% + 10px);
      opacity: 1;
      pointer-events: all;
    }

    .be-menu-action {
      p {
        background: #fff;
        color: $primary;
        transition: 0.2s all;
        line-height: 1em;
        white-space: nowrap;
        padding: 12px 18px;
        margin: 0;
        font-size: 14px;
        overflow: hidden;
      }

      &:first-of-type {
        p {
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }
      }

      &:last-of-type {
        p {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }

      &:hover {
        p {
          color: #fff;
          background: $primary;
        }
      }
    }
  }
}
