@import "../../../../../assets/styles/vars.scss";

.e-dnd {
  max-height: 410px;
  overflow-y: auto;
}

.exercise-item {
  border: 2px solid $backgroundGrey;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  .exercise-item-upper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 10px 1fr 55px;
    align-items: center;
    grid-gap: 12px;
    padding: 10px 20px;
  }

  .exercise-item-lower {
    display: none;
    padding: 10px 20px;

    &.is-showing {
      display: block;
    }

    .add-list-item {
      margin: 2px 0 12px !important;
      font-size: 14px;

      svg {
        height: 13px !important;
        margin-right: 6px !important;
      }
    }

    .add-set-row {
      h6 {
        font-size: 12px;
        font-weight: 500;
        color: $placeholderGrey;
        margin: 0 0 2px;
        opacity: 0.6;
      }

      .add-set-inputs {
        display: grid;
        grid-gap: 15px;
        justify-content: baseline;
        grid-template-columns: 1fr 1fr 1fr auto;
        margin: 0 0 15px;

        .ui-button {
          height: fit-content;
          margin: auto 0 8px;
        }

        .add-set-input {
          position: relative;

          p {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 500;
            color: #4e5c70;
            margin: 0 0 4px;
            cursor: pointer;

            svg {
              height: 10px;
              margin-left: 5px;

              path {
                fill: #4e5c70;
              }
            }
          }

          .set-unit-options {
            position: absolute;
            left: 0;
            bottom: calc(100%);
            box-shadow: 0 0 30px rgba(78, 78, 78, 0.2);
            background: #fff;
            border-radius: 2px;
            transition: 0.2s all;
            opacity: 0;
            border-radius: 2px;
            border: 4px solid #fff;
            overflow: hidden;
            pointer-events: none;

            &.is-active {
              bottom: calc(100% + 6px);
              opacity: 1;
              pointer-events: all;
            }

            .unit-option {
              p {
                background: #fff;
                color: $primary;
                transition: 0.2s all;
                line-height: 1em;
                white-space: nowrap;
                padding: 8px 12px;
                margin: 0;
                font-size: 12px;
                overflow: hidden;
              }

              &:first-of-type {
                p {
                  border-top-left-radius: 2px;
                  border-top-right-radius: 2px;
                }
              }

              &:last-of-type {
                p {
                  border-bottom-left-radius: 2px;
                  border-bottom-right-radius: 2px;
                }
              }

              &:hover {
                p {
                  color: #fff;
                  background: $primary;
                }
              }
            }
          }
        }
      }
    }
  }

  &:nth-child(odd) {
    background: $backgroundGrey;
  }

  p {
    margin: 0;
  }

  .ex-drag {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      height: 17px;
      width: auto;
    }
  }

  .ex-title {
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .ex-remove {
    p {
      color: $red;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
