@import "../../assets/styles/vars.scss";

.exercise-set-list {
  margin-top: -8px;
  margin-left: 12px;
  margin-bottom: 10px;

  small {
    display: block;
    font-weight: 400;
  }

  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    font-size: 14px;
    text-transform: lowercase;
  }
}

.workout-summary-para {
  height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px !important;
  font-weight: 300 !important;
  color: $placeholderGrey !important;
  margin: 10px 16px 0 !important;
  line-height: 1.4em !important;
}
