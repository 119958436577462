@import "../../assets/styles/vars.scss";

.metrics-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  &.up {
    color: $green02;

    svg {
      path {
        fill: $green02;
      }
    }
  }

  &.down {
    color: $red;

    svg {
      path {
        fill: $red;
      }
    }
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    margin: 0 12px;

    svg {
      margin-right: 6px;
    }
  }
}

.metrics-grid {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1120px;
}

@media (max-width: 1500px) {
  .stat-card {
    .stat-numbers {
      .big-stat {
        p {
          font-size: 32px;
        }
      }
    }
  }
}
