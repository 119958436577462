@import "../../../../assets/styles/vars.scss";

.add-meal-modal {
  .rdtPicker {
    .rdtSwitch {
      font-size: 13px;
      height: 22px;
    }

    .rdtNext,
    .rdtPrev {
      font-size: 13px;
      height: 22px;
    }
  }

  .dow {
    font-size: 13px;
    height: 20px;
  }

  .rdtDay {
    font-size: 14px;
    height: 22px;
    transition: 0.1s all;

    &:hover,
    &.rdtActive {
      color: #fff;
      background-color: $primary;

      &:hover {
        background-color: $primary;
      }
    }

    &.rdtToday {
      &:before {
        border-bottom: 7px solid $secondary;
      }
    }
  }
}

.input-group-column {
  .input-block {
    &:not(:last-child) {
      margin-bottom: 0 !important;
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.event-timespan {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: -14px 0 25px;
  opacity: 0.45;
}
