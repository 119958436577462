@import "../../../assets/styles/vars.scss";

.mobile-navigation {
  position: fixed;
  z-index: 2005;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: 0.2s all;

  .mobile-navigation-inner {
    position: absolute;
    z-index: 2005;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
  }

  &.is-showing {
    opacity: 1;
    pointer-events: all;
  }

  .wn-logo {
    display: flex;
    align-items: center;
    margin: 25px auto 20px;

    img {
      display: block;
      max-width: 245px;
      margin: auto;
    }
  }

  .wn-links {
    ul {
      display: grid;
      grid-gap: 14px;
      grid-template-columns: 1fr;
      margin: 0;
      padding: 0;

      a {
        color: $primary;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        margin: 0;
        line-height: 1em;
      }
    }
  }

  .wn-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 25px;

    a {
      color: #fff;
      text-decoration: none;
      display: inline-block;
      border-radius: 5px;
      width: 110px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      transition: 0.2s all;
      font-size: 15px;

      &:first-of-type {
        background: $primary;
        margin-right: 10px;

        &:hover {
          background: darken($primary, 5%);
        }
      }

      &:last-of-type {
        background: $secondary;

        &:hover {
          background: darken($secondary, 5%);
        }
      }
    }
  }
}

.website-navigation {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  align-items: center;
  grid-template-columns: 300px 1fr 300px;
  padding: 30px 35px 0;

  .wn-logo {
    display: flex;
    align-items: center;

    img {
      max-width: 220px;
      margin: auto;
    }
  }

  .wn-links {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;

      a {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        margin: 0 22px;
      }
    }
  }

  .wn-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      color: #fff;
      text-decoration: none;
      display: inline-block;
      border-radius: 8px;
      width: 125px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      transition: 0.2s all;

      &:first-of-type {
        background: $primary;
        margin-right: 10px;

        &:hover {
          background: darken($primary, 5%);
        }
      }

      &:last-of-type {
        background: $secondary;

        &:hover {
          background: darken($secondary, 5%);
        }
      }
    }
  }

  .wn-toggle {
    display: none;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    padding: 20px 5px;
    cursor: pointer;

    .wn-toggle-bars {
      position: relative;
      display: block;
      width: 35px;
      height: 2px;
      background: #fff;

      &:after {
        content: "";
        position: absolute;
        width: 35px;
        height: 2px;
        background: #fff;
        top: -8px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 35px;
        height: 2px;
        background: #fff;
        bottom: -8px;
      }
    }
  }
}

@media (max-width: 850px) {
  .website-navigation {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0;

    .wn-logo {
      img {
        max-width: 174px;
      }
    }

    .wn-links,
    .wn-buttons {
      display: none;
    }

    .wn-toggle {
      display: block;
    }
  }
}
