.workout-sets {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4em;
  margin: -10px 0 18px;
}

.excerise-inputs {
  display: flex;
  align-items: center;

  button {
    margin-left: 14px;
  }
}

.add-meal-modal-inputs {
  button {
    width: fit-content;
  }
}
