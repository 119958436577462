.setup-price-window {
  height: 100%;
  width: 100%;

  .price-card-setup-fields {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0 0;
  }

  .input-block {
    margin-bottom: 0 !important;
  }

  .inputfield-wrapper,
  .ui-select-upper,
  .ui-select-dropdown {
    width: 285px;

    input {
      width: 100%;
    }
  }

  .additional-information {
    max-width: 315px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5em;
    color: #7e838d;
    margin: 0;
  }

  .button {
    margin: 0 !important;
  }
}
