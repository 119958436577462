@import "../../../assets/styles/vars.scss";

.ui-toast-alert {
  position: relative;
  width: 425px;
  min-height: 60px;
  border-radius: 4px;
  border-left: 4px solid $blue;
  padding: 10px 72px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.12);

  &.SUCCESS {
    border-left: 4px solid $green;

    .ui-toast-icon {
      background: $green;
    }
  }

  &.ALERT {
    border-left: 4px solid $orange;

    .ui-toast-icon {
      background: $orange;
    }
  }

  &.ERROR {
    border-left: 4px solid $red;

    .ui-toast-icon {
      background: $red;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .ui-toast-icon {
    position: absolute;
    z-index: 2;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: $blue;
    top: calc(60px / 2);
    left: 20px;
    transform: translateY(-50%);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      max-height: 15px;
      width: 100%;
      max-width: 12px;
      transform: translate(-50%, -50%);

      path {
        fill: #fff;
      }
    }
  }

  .ui-toast-title {
    font-size: 15px;
    font-weight: 700;
    margin: 0;
  }

  .ui-toast-body {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3em;
    margin: 2px 0 0;
    color: $placeholderGrey;
  }

  .ui-toast-dismiss {
    position: absolute;
    z-index: 2;
    top: calc(60px / 2);
    right: 20px;
    cursor: pointer;
    transform: translateY(-50%);

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }

    svg {
      height: 15px;
      width: 15px;
      transition: 0.2s all;
    }
  }
}
