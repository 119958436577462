@import "../../assets/styles/vars.scss";

.client-card {
  position: relative;

  &:hover {
    &:after {
      opacity: 1;
    }

    .client-card-inner {
      border: 5px solid $primary;
    }
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $gradient;
    opacity: 0;
    transition: 0.2s all;
    filter: blur(25px);
  }

  .client-card-inner {
    position: relative;
    z-index: 2;
    padding: 24px 20px;
    border-radius: 4px;
    background: #fff;
    border: 5px solid $accentGrey;
    transition: 0.2s all;

    .client-card-profile-picture {
      position: relative;
      height: 64px;
      width: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: $secondary;
      margin: 0 auto 20px;

      .profile-initials {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 600;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .client-card-details {
      text-align: center;

      .client-card-name {
        font-size: 18px;
        font-weight: 600;
        color: $placeholderGrey;
        margin: 0;
        line-height: 1em;
      }
    }

    .button {
      width: 100%;
      margin: 20px auto 0;
      padding: 0 10px;
    }
  }
}

.client-equipment-list {
  margin: 12px 0;

  p {
    margin: 0;
    line-height: 1em;
    font-size: 15px;
    font-weight: 500;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.assign-meal-inputs {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr auto;
  margin-bottom: 16px;

  .input-block {
    input {
      padding: 8px 16px;
    }
  }

  .button {
    margin-top: 0 !important;
  }
}

.add-event-modal {
  margin-bottom: 14px;
  .button {
    margin-top: 0 !important;
  }
}

.assign-workout-column {
  display: grid;
  grid-gap: 14px;
  grid-template-columns: 1fr;

  .input-block {
    width: 100%;
    margin-bottom: 0 !important;

    input {
      width: 100%;
    }
  }
}

.client-modal-tab {
  &.summary {
    .remove-client {
      p {
        font-size: 14px;
        margin: 0 0 4px;

        &:last-child {
          font-size: 15px;
          font-weight: 500;
          color: $red;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-btn-group {
    button {
      font-size: 12px;
      padding: 4px 10px !important;
    }
  }

  .rbc-toolbar-label {
    font-size: 16px;
  }

  .rbc-time-header-content {
    border: none;
  }

  .rbc-events-container {
    margin-right: 0 !important;

    .rbc-event {
      padding: 2px 4px;
    }

    .rbc-event-content {
      font-size: 12px;
    }
  }

  .rbc-time-content {
    border-top: none;
  }

  .rbc-time-gutter {
    .rbc-timeslot-group {
      border: none;

      .rbc-label {
        position: relative;
        top: -4px;
        padding: 0 5px 0 0;
        line-height: 1em;
      }
    }
  }

  .rbc-day-slot {
    .rbc-timeslot-group {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.calorie-targets {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr auto;
}

.tab-workout-columns {
  display: flex;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 100%;
  min-height: 120px;

  .workouts-divider {
    width: 2px;
    background: #edf0f4;
    margin: 0 40px;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;
    margin: 0 0 10px;
  }

  .no-data {
    color: #a2a9b5;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    line-height: 1em;
  }

  .workouts-grid {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;
  }

  .upcoming-workouts,
  .previous-workouts {
    flex: 0 0 255px;
  }

  .upcoming-workouts {
    .workout-card {
      width: 100%;

      &:first-child {
        border: 5px solid $primary;
        background: $primary;
        color: #fff;

        .workout-card-title {
          &:before {
            position: relative;
            content: "UP NEXT:";
            font-size: 12px;
            line-height: 1em;
            top: -10px;
          }

          small {
            color: #fff;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .workout-card {
    position: relative;
    transition: 0.2s all;
    position: relative;
    z-index: 2;
    padding: 14px 20px;
    border-radius: 4px;
    background: #fff;
    border: 5px solid $accentGrey;
    transition: 0.2s all;

    &.previous {
      opacity: 0.35;
    }

    &:hover {
      opacity: 1;
    }

    .workout-card-title {
      p {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        line-height: 1em;
      }

      small {
        color: #a2a9b5;
        font-weight: 500;
        font-size: 12px;
        margin: 0;
        line-height: 1em;
      }
    }

    .workout-with {
      font-size: 14px;
      margin: 15px 0 0;
      line-height: 1em;
    }
  }
}

.trainer-workouts-grid {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr;
  width: 100%;

  .trainer-workout-in-client {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 6px 6px 16px;

    &:nth-child(odd) {
      background: #f8f8f8;
    }

    p {
      margin: 0 auto 0 0;
      line-height: 1em;
      font-size: 14px;
    }

    button {
      font-size: 13px;
      padding: 5px 13px;
      margin-left: 6px;
    }
  }
}

.create-workout-for-client {
  margin-top: 24px;

  .ui-button {
    font-size: 14px;
    padding: 8px 26px;
  }
}
