@import "../../../assets/styles/vars.scss";

.continue-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    max-width: 250px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin: 15px 0 5px;
  }

  small {
    font-size: 12px;
    opacity: 0.6;
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 14px;
    font-weight: 500;
    height: 42px;
    width: fit-content;
    padding: 0 28px;
    border-radius: 2px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    color: #fff;
    background: $primary;
    transition: 0.2s all;
    text-decoration: none;
    margin-top: 22px;
  }
}
