@import "react-big-calendar/lib/sass/styles";
@import "../../assets/styles/vars.scss";

.rbc-calendar {
  font-family: "Poppins";
  .rbc-toolbar {
    .rbc-btn-group {
      &:first-child {
        button {
          &:nth-child(2) {
            position: relative;
            font-size: 0;

            &:after {
              content: "\2190";
              font-size: 18px;
              font-weight: 600;
              line-height: 1em;
            }
          }

          &:nth-child(3) {
            position: relative;
            font-size: 0;

            &:after {
              content: "\2192";
              font-size: 18px;
              font-weight: 600;
              line-height: 1em;
            }
          }
        }
      }
    }
  }
}

.rbc-btn-group {
  padding: 5px;
  background: #f6f7fb;
  border-radius: 5px;

  button {
    font-family: "Poppins";
    color: $primary;
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    border: none !important;
    transition: 0.2s all;
    cursor: pointer;

    &:focus {
      color: $primary;
      background: transparent;
    }

    &:hover,
    &.rbc-active {
      color: #fff;
      box-shadow: unset;
      background: $primary;

      &:hover,
      &:focus {
        color: #fff;
        box-shadow: unset;
        background: darken($primary, 5%);
      }
    }
  }
}

.rbc-row-bg {
  background: #f6f6f6;
}

.rbc-off-range-bg {
  background: #f1f1f1;
}

.rbc-month-view,
.rbc-time-view,
.rbc-agenda-view {
  border: none;
}

.rbc-month-header,
.rbc-time-header,
.rbc-agenda-header {
  padding-bottom: 10px;
}

.rbc-agenda-view {
  table {
    &.rbc-agenda-table {
      border: none;

      .rbc-header {
        color: $placeholderGreyLight;
        font-weight: 500;
        font-size: 12px;
      }
    }

    tbody {
      tr {
        td {
          font-size: 16px;

          &.rbc-agenda-date-cell,
          &.rbc-agenda-time-cell {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.rbc-header {
  border: none !important;

  span {
    color: $placeholderGreyLight;
    font-weight: 500;
    font-size: 12px;
  }
}

.rbc-event {
  background: $secondary;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 2px;
  border: none !important;

  &.rbc-selected {
    background: darken($secondary, 5%);
  }

  .rbc-event-content {
    line-height: 1.4em;
  }
}

.rbc-date-cell {
  font-size: 14px;
}

.rbc-time-slot {
  span {
    color: $placeholderGreyLight;
    font-weight: 500;
    font-size: 12px;
  }
}

.rbc-toolbar {
  .rbc-toolbar-label {
    font-size: 20px;
    font-weight: 600;
  }
}

.rbc-current-time-indicator {
  height: 2px;
  background: $primary;
}

.event-actions {
  display: flex;
  align-items: center;

  button {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}
