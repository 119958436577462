.viewing-event-modal {
  h1 {
    margin: 0 0 20px;
    font-size: 22px;
    font-weight: 500;
  }

  h2 {
    margin: -12px 0 25px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4em;
    max-width: 450px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 12px;
    line-height: 1em;
  }

  ul {
    margin: 0;
  }
}
