@import "../../assets/styles/vars.scss";

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gradient;
  height: 100vh;
}

#login-form {
  width: 450px;
  text-align: center;
  background: #fff;
  padding: 50px;

  img {
    max-width: 115px;
    margin-bottom: 35px;
  }

  .input-block {
    margin: 0 auto 15px;
  }

  .ui-button {
    margin: 25px auto 0;
    min-width: 135px;
  }

  .global-form-error {
    p {
      color: $red;
      font-size: 15px;
      margin-bottom: 0;
      line-height: 1.2em;
    }
  }
}

.account-type-card {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 22px;
  transition: 0.2s all;
  max-width: 550px;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  &:hover {
    cursor: pointer;
    background: #ecfdff;
    border: 1px solid $secondary;
  }

  .type-logo {
    height: 52px;
    width: 52px;
    border-radius: 5px;
    background: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 20px;
      width: auto;

      path {
        fill: #fff;
      }
    }
  }

  .type-text {
    margin: 0 20px;

    h5 {
      font-size: 18px;
      line-height: 1em;
      margin: 0 0 8px;
      font-weight: 500;
    }

    h6 {
      font-size: 14px;
      line-height: 1em;
      color: #959da9;
      font-weight: 400;
      margin: 0;
    }
  }
}
