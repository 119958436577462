@import "../../assets/styles/vars.scss";

#modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1005;
  pointer-events: none;

  &.active {
    pointer-events: all;
  }
}

.modal-page {
  position: absolute;
  z-index: 10005;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.2s all;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .modal-box {
    width: 100%;
    max-width: 640px;
    background: #fff;
    opacity: 1;
    transition: 0.2s all;
    border-radius: 5px;
    max-height: 84vh;
    overflow-y: visible;
    margin-top: 8vh;
    display: flex;
    flex-direction: column;

    &.slim {
      max-width: 490px;
    }

    &.super-slim {
      max-width: 375px;
    }

    &.wide {
      max-width: 1075px;
      max-height: 710px;
      max-height: 84vh;
    }

    &.with-nav {
      max-width: 1200px;
      max-height: 750px;
      height: 100%;

      .modal-body {
        display: block;
        height: calc(100% - 64px);
      }
    }

    &.large {
      width: 90vw;
      max-width: 1200px;
      height: 80vh;
      max-height: 1000px;
    }

    &.flex {
      display: flex;
      flex-direction: column;
    }

    &.no-pad {
      .modal-body {
        padding: 0;
      }
    }

    &.min-height {
      height: 84vh;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #edf0f4;
      padding: 0 24px;
      height: 64px;
      flex: 0 0 64px;

      .modal-title {
        h1 {
          display: block;
          line-height: 1em;
          font-size: 22px;
          font-weight: 600;
          color: #000;
          margin: 0;
        }

        h2 {
          display: block;
          line-height: 1em;
          font-size: 13px;
          font-weight: 400;
          color: #959da9;
          margin: 4px 0 0;
        }
      }

      .modal-close {
        cursor: pointer;
        transform-origin: center;
        transition: 0.2s all;

        svg {
          height: 12px;
          width: 12px;
        }

        path {
          fill: $red;
          opacity: 1;
        }

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .modal-tab-navigations {
      position: relative;
      display: flex;
      align-items: center;
      margin: -8px 0 14px;

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: calc(100% - 2px);
        left: 0;
        right: 0;
        border-bottom: 2px solid $accentGrey;
      }

      .modal-tab-nav-link {
        position: relative;
        z-index: 2;
        font-size: 14px;
        margin: 0;
        color: $placeholderGrey;
        padding-bottom: 5px;
        cursor: pointer;
        font-weight: 500;
        border-bottom: 2px solid $accentGrey;
        transition: 0.2s all;

        &:not(:last-child) {
          margin-right: 18px;
        }

        &:hover,
        &.active {
          color: $primary;
          border-bottom: 2px solid $primary;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      padding: 24px;

      .ui-select-wrapper,
      .input-block {
        &:not(:last-of-type) {
          margin-bottom: 18px;
        }
      }

      .client-modal-tab {
        flex: 1 1;
      }

      .button {
        margin-top: 24px;
      }
    }
  }
}
