.track-new-metric-inputs {
  .ui-select-wrapper {
    position: relative;

    &:first-of-type {
      z-index: 3;
    }

    &:last-of-type {
      z-index: 2;
    }
  }
}
