.document {
  h1 {
  }

  h2 {
  }

  h3 {
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4em;

    strong {
      font-weight: 600;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
