@import "../../../assets/styles/vars.scss";

.viewing-workout {
  .workout-summary {
    font-size: 17px;
    margin: 5px 0 25px;
    max-width: 88%;
  }

  button {
    width: fit-content;
    margin: 30px 0 0;
  }
}

.exercise-table-layout {
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 2px solid $accentGrey;
    margin-bottom: 10px;
  }

  tr {
    td {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 105px;
      }
    }

    .metric-unit {
      font-size: 13px;
      margin-left: 2px;
    }

    &.exercise-header {
      td {
        &:first-child {
          color: #000;
          font-size: 15px;
          font-weight: 500;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          color: #a2a9b5;
          font-size: 14px;
        }
      }
    }
  }
}
