@import "../../../assets/styles/vars.scss";

.marketplace-trainer {
  position: relative;
  max-width: 275px;

  &:hover {
    &:after {
      opacity: 1;
    }

    .trainer-inner {
      border: 5px solid $primary;
    }
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $gradient;
    opacity: 0;
    transition: 0.2s all;
    filter: blur(25px);
  }

  .trainer-inner {
    position: relative;
    z-index: 2;
    padding: 24px 20px;
    border-radius: 4px;
    background: #fff;
    border: 5px solid $accentGrey;
    transition: 0.2s all;

    .trainer-price {
      position: absolute;
      top: 12px;
      right: 16px;

      p {
        margin: 0;
        font-size: 16px;
        text-align: right;
        font-weight: 600;
        color: $placeholderGrey;

        span {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }

    .trainer-profile-picture {
      position: relative;
      height: 94px;
      width: 94px;
      border-radius: 50%;
      overflow: hidden;
      background: $secondary;
      margin: 0 auto 20px;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .trainer-details {
      text-align: center;

      .trainer-name {
        font-size: 18px;
        font-weight: 600;
        color: $placeholderGrey;
        margin: 0;
        line-height: 1em;
      }

      .trainer-location {
        font-size: 12px;
        font-weight: 400;
        color: $subtitleGrey;
        margin: 10px 0 0;
        line-height: 1em;
      }

      .trainer-availability {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 500;
        margin: 6px 0 0 0;

        &.available {
          color: $green;

          svg {
            margin-right: 4px;

            path {
              fill: $green;
            }
          }
        }
      }
    }

    .trainer-tags {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      .trainer-tag {
        color: $subtitleGrey;
        font-size: 10px;
        font-weight: 600;
        border: 2px solid $subtitleGrey;
        border-radius: 20px;
        padding: 3px 8px;
        line-height: 1em;
        margin-right: 5px;
        white-space: nowrap;
      }

      .trainer-tag-more {
        font-size: 10px;
        font-weight: 600;
        color: #fff;
        background: $secondary;
        padding: 3px 8px;
        border-radius: 20px;
      }
    }

    .trainer-bio {
      p {
        font-size: 13px;
        text-align: center;
        color: $placeholderGrey;
        margin: 20px 0;
        line-height: 1.45em;
      }
    }

    .button {
      margin: 20px auto 0;
      padding: 0 52px;
    }
  }
}
