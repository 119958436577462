@import "../../../assets/styles/vars.scss";

.web-button {
  color: #fff;
  background: $primary;
  text-decoration: none;
  padding: 12px 28px;
  font-size: 16px;
  border-radius: 6px;
  transition: 0.2s all;

  &.small {
    font-size: 14px;
    padding: 10px 28px;
  }

  &:hover {
    background: darken($primary, 5%);
  }
}

.general-banner {
  position: relative;
  height: 575px;
  background-size: cover;
  background-position: center;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      128deg,
      rgba(204, 132, 221, 0) 0%,
      rgba(204, 132, 221, 0.98) 100%
    );
  }

  .banner-text {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 775px;

    h2 {
      color: #fff;
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      opacity: 0.75;
      line-height: 1.2em;
    }

    h1 {
      color: #fff;
      font-size: 62px;
      font-weight: 500;
      margin: 15px 0 55px;
      line-height: 1.2em;
    }
  }
}

.homepage-banner {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      128deg,
      rgba(204, 132, 221, 0) 0%,
      rgba(204, 132, 221, 0.98) 100%
    );
  }

  .banner-text {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 12.5vw;
    transform: translateY(-50%);
    max-width: 775px;

    h2 {
      color: #fff;
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      opacity: 0.75;
      line-height: 1.2em;
    }

    h1 {
      color: #fff;
      font-size: 62px;
      font-weight: 500;
      margin: 15px 0 55px;
      line-height: 1.2em;
    }
  }
}

.padding-block {
  padding: 100px 0;

  &.no-bottom-padding {
    padding: 100px 0 0;
  }
}

.gray-bg {
  background: #545454;
}

.max-width {
  max-width: 1450px;
  margin: 0 auto;
}

.intro-cards {
  .ic-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 100px;

    .ic-card {
      position: relative;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 285px;

      svg {
        height: 50px;
        width: 50px;
        margin-bottom: 35px;

        path {
          fill: $primary;
        }
      }

      h3,
      p,
      a {
        position: relative;
        z-index: 2;
      }

      h3 {
        color: #000;
        font-size: 24px;
        font-weight: 500;
        margin: 0 0 15px;
        line-height: 1em;
      }

      p {
        color: #000;
        font-size: 16px;
        opacity: 0.9;
        margin: 0 0 30px;
        line-height: 1em;
      }
    }
  }
}

.heading-block {
  &.is-center {
    text-align: center;

    hr {
      margin: 8px auto 0;
    }

    p {
      max-width: 825px;
      text-align: center;
      margin: 30px auto 0;
    }
  }

  &.is-white {
    color: #fff !important;
  }

  small {
    color: $secondary;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1em;
  }

  h4 {
    font-size: 42px;
    font-weight: 400;
    margin: 0;
    line-height: 1.25em;
  }

  hr {
    width: 150px;
    height: 2px;
    border: none;
    background: $secondary;
    margin: 8px 0 0;
  }

  p {
    font-size: 17px;
    line-height: 1.75em;
  }
}

.flex-block {
  display: grid;
  grid-gap: 50px;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);

  .text {
    p {
      font-size: 16px;
      max-width: 400px;
      line-height: 1.75em;
      margin: 0;

      &:first-of-type {
        color: $secondary;
        margin: 30px 0 25px;
      }
    }

    .web-button {
      display: inline-block;
      margin-top: 35px;
    }
  }

  .single-image {
    .ic-image-wrapper {
      position: relative;
      min-height: 550px;

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $gradient;
        opacity: 0;
        transition: 0.2s all;
        filter: blur(25px);
      }

      img {
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .image-collage {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 1fr);

    .ic-column {
      &:first-of-type {
        padding: 44% 0;

        .ic-image-wrapper {
          height: 100%;
        }
      }

      .ic-image-wrapper {
        position: relative;
        min-height: 390px;

        &:not(:last-of-type) {
          margin-bottom: 30px;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}
.trainers-features {
  margin-top: 10px;
}

.fitportal-features-grid {
  margin-top: 75px;

  .ff-card {
    position: relative;
    min-height: 445px;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.55);
      opacity: 0;
      transition: 0.2s all;
    }

    &:hover {
      .ff-card-title {
        bottom: -50px;
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }

      .ff-card-more {
        opacity: 1;
      }
    }

    .ff-card-title {
      position: absolute;
      top: 50%;
      left: 25px;
      right: 25px;
      transform: translateY(-50%);
      background: rgba(0, 0, 0, 0.55);
      padding: 32px 10px;
      transition: 0.2s all;

      h5 {
        color: #fff;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        margin: 0;
        line-height: 1em;
      }
    }

    .ff-card-more {
      position: absolute;
      z-index: 3;
      top: 50px;
      left: 50px;
      right: 50px;
      bottom: 50px;
      background: rgba(80, 186, 200, 0.8);
      opacity: 0;
      transition: 0.2s all;
      text-align: center;
      padding: 50px 45px;

      h5 {
        color: #fff;
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        margin: 0;
        line-height: 1em;
      }

      hr {
        width: 150px;
        height: 2px;
        border: none;
        background: #fff;
        margin: 10px auto 25px;
      }

      p {
        color: #fff;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.75em;
      }
    }
  }
}

.testimonials-slider {
  margin-top: 75px;
  .testimonial-card {
    margin: 0 auto;

    .tc-body {
      text-align: center;

      p {
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.75em;
      }
    }

    .tc-image {
      position: relative;
      height: 110px;
      width: 110px;
      margin-top: 30px;
      border-radius: 100%;
      overflow: hidden;
      border: 2px solid $secondary;
      margin: 45px auto 20px;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .tc-name {
      color: #fff;
      text-align: center;

      p {
        margin: 0;

        &:first-of-type {
          font-size: 26px;
          font-weight: 500;
        }

        &:last-of-type {
          font-size: 16px;
          opacity: 0.8;
        }
      }
    }
  }
}

.testimonials-wrapper {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.testimonials-slider-prev,
.testimonials-slider-next {
  background: $primary;
  height: 45px;
  width: 45px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    background: darken($primary, 5%);
  }
}

.testimonials-slider-prev {
  position: absolute;
  top: 50px;
  left: -200px;
}

.testimonials-slider-next {
  position: absolute;
  top: 50px;
  right: -200px;
}

.home-trainer-cards {
  margin-top: 75px;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);

  .ht-card {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.15);

    .ht-card-image {
      position: relative;
      height: 450px;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .ht-card-text {
      text-align: center;
      padding: 30px 10px;

      p {
        margin: 0;
        line-height: 1em;

        &:first-of-type {
          font-size: 30px;
          font-weight: 500;
        }

        &:last-of-type {
          font-size: 16px;
          font-weight: 500;
          opacity: 0.8;
          margin: 12px 0 18px;
        }
      }

      .ht-card-socials {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 16px;
          width: auto;
          margin: 0 8px;
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  .max-width {
    padding: 0 45px;
  }

  .padding-block {
    padding: 80px 0;
  }

  .website-contact {
    .wc-form {
      margin-left: 45px !important;
    }
  }
}

@media (max-width: 850px) {
  .homepage-banner {
    .banner-text {
      text-align: center;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 360px;

      h1 {
        font-size: 42px;
        font-weight: 700;
        line-height: 1em;
      }

      h2 {
        max-width: 260px;
        margin: 0 auto 22px;
        font-size: 18px;
      }
    }
  }

  .padding-block {
    padding: 45px 0;

    &.no-bottom-padding {
      padding: 45px 0 0;
    }
  }

  .max-width {
    padding: 0 20px;
  }

  .intro-cards {
    .ic-grid {
      grid-template-columns: 1fr;
      margin-bottom: 45px;

      h3,
      p {
        max-width: 90%;
        text-align: center;
      }
    }
  }

  .heading-block {
    padding: 0 20px;

    small {
      font-size: 12px;
    }

    h4 {
      font-size: 28px;
    }

    hr {
      margin: 8px auto 0;
    }
  }

  .flex-block {
    grid-template-columns: 1fr;

    .text {
      text-align: center;
    }

    .image-collage {
      .ic-column {
        .ic-image-wrapper {
          min-height: 200px;
        }
      }
    }
  }

  .fitportal-features-grid {
    margin-top: 30px;
    grid-template-columns: 1fr;

    .ff-card {
      min-height: 350px;

      &:after {
        opacity: 1;
      }

      .ff-card-more {
        opacity: 1;
        top: 18px;
        left: 18px;
        right: 18px;
        bottom: 18px;
        padding: 30px;

        h5 {
          font-size: 24px;
        }
      }

      &:hover {
        .ff-card-title {
          bottom: -50px;
          opacity: 0;
        }
      }

      .ff-card-title {
        display: none;
      }
    }
  }

  .home-trainer-cards {
    margin-top: 35px;
    grid-template-columns: 1fr;

    .ht-card {
      .ht-card-image {
        height: 280px;
      }

      .ht-card-text {
        p {
          &:first-of-type {
            font-size: 22px;
          }

          &:last-of-type {
            font-size: 16px;
          }
        }
      }
    }
  }

  .testimonials-slider-prev,
  .testimonials-slider-next {
    display: none;
  }

  .testimonials-slider {
    padding: 0 20px;
    margin-top: 35px;

    .testimonial-card {
      .tc-body {
        p {
          font-size: 16px;
        }
      }

      .tc-name {
        p {
          &:first-of-type {
            font-size: 20px;
          }

          &:last-of-type {
            font-size: 14px;
          }
        }
      }
    }
  }
}
