@import "../../assets/styles/vars.scss";

.toggle-type-views {
  display: flex;
  align-items: center;
  margin: 18px 0 0;

  .input-block {
    width: fit-content;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.equipment-list-wrapper {
  .equipment-list {
    margin-top: 20px;

    .no-equipment-yet {
      font-size: 16px;
      line-height: 1em;
      opacity: 0.5;
      color: $placeholderGrey;
      margin: 0;
    }

    .equipment-list-item {
      position: relative;
      max-width: 810px;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 195px 265px 150px 1fr;
      padding: 16px 26px;
      align-items: center;

      &.show {
        display: grid !important;
      }

      &.hide {
        display: none;
      }

      p {
        margin: 0;

        &:nth-child(1) {
          font-size: 18px;
          font-weight: 500;
          line-height: 1em;
        }

        &:nth-child(2) {
          color: $placeholderGrey;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.25em;

          span {
            &.placeholder {
              opacity: 0.5;
            }
          }
        }
      }

      .ea-locations {
        display: flex;
        align-items: center;

        .ea-location {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-right: 10px;
          }

          svg {
            width: 12px;
            margin-right: 4px;
            fill: $placeholderGreyLight;
          }

          p {
            color: $placeholderGreyLight;
          }

          &.available {
            svg {
              path {
                fill: $green;
              }
            }

            p {
              color: $green;
            }
          }
        }
      }

      .remove-equipment {
        display: flex;

        p {
          display: inline;
          font-size: 13px;
          color: $red;
          cursor: pointer;
          margin-left: auto;
        }
      }

      &:nth-child(odd) {
        background: $backgroundGrey;
      }
    }
  }
}
