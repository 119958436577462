@import "../../../assets/styles/vars.scss";

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 15px;
  font-weight: 500;
  height: 42px;
  width: fit-content;
  padding: 0 28px;
  border-radius: 2px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  color: #1d1f2f;
  background: #fff;
  transition: 0.2s all;
  min-height: 42px;

  &.fw {
    width: 100%;
  }

  &:hover {
    background: #fafafa;
  }

  &:disabled {
    opacity: 0.4;
  }

  &.primary {
    color: #fff;
    background: $primary;

    &:hover {
      background: darken($primary, 5%);
    }
  }

  &.blue {
    color: #fff;
    background: $blue;

    &:hover {
      background: darken($blue, 5%);
    }
  }

  &.green {
    color: #fff;
    background: $green;

    &:hover {
      background: darken($green, 5%);
    }
  }

  &.red {
    color: #fff;
    background: $red;

    &:hover {
      background: darken($red, 5%);
    }
  }

  &.center {
    margin: auto;
  }

  img {
    width: 28px;
    height: 28px;
    margin-left: 4px;
  }

  .spinner {
    display: none;
    width: 12px;
    height: 12px;
    background: $green;
    margin-left: 8px;
  }

  &:focus {
    outline: none !important;
  }

  &.processing {
    pointer-events: none;

    .spinner {
      display: block;
    }
  }
}
