.app-alerts-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20001;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 22px 22px 0;
  pointer-events: none;

  .app-alerts-column {
    .ui-toast-alert {
      pointer-events: all;

      &:not(:last-child) {
        margin-bottom: 22px;
      }
    }
  }
}
