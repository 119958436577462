@import "../../../../assets/styles/vars.scss";

.ui-select-wrapper {
  position: relative;

  &.has-error {
    .ui-select-upper {
      border: 1px solid red;
    }

    small {
      color: red;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 0;
    color: #4e5c70;
    opacity: 0.6;
  }

  label {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #4e5c70;
    margin-bottom: 4px;
  }

  .ui-input-upper-placeholder {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 14px;
    font-weight: 500;
    color: #a8afbb;
    transition: 0.25s all;
    opacity: 0;
    padding: 3px 6px;

    &.active {
      top: 0px;
      left: 14px;
      opacity: 1;
      font-size: 12px;
      background: #fff;
    }
  }

  .ui-select-upper {
    position: relative;
    z-index: 1006;
    padding: 9px 18px;
    cursor: pointer;
    background: #f6f7fb;
    border: 2px solid #f6f7fb;
    transition: 0.2s all;
    min-height: 39px;
    border-radius: 2px;

    &.dropdown-showing {
      border-radius: 2px 2px 0 0;

      .ui-select-icon {
        svg {
          transform: translateY(-50%) rotate(180deg);

          path {
            fill: #959da9;
          }
        }
      }
    }

    &.has-value {
      background: #fff;
      border: 2px solid $secondary;
      color: #4e5c70;

      .ui-select-placeholder {
        color: #4e5c70;
        opacity: 1;
      }
    }

    .ui-select-placeholder {
      position: relative;
      font-size: 14px;
      font-weight: 400;
      color: #a8afbb;
      min-height: 19px;
      margin: 0;
      pointer-events: none;

      .ui-multiple-selected-options-row {
        position: absolute;
        z-index: 2;
        display: flex;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        align-items: center;

        .ui-multiple-selected-option {
          color: #5d6373;
          background: #e7e7e7;
          padding: 2px 6px;
          border-radius: 3px;
          font-size: 12px;
          border: 1px solid #d5d5d5;
          transition: 0.2s all;
          white-space: nowrap;

          &.is-hidden {
            opacity: 0;
            pointer-events: none;
            position: absolute;
          }

          &:hover {
            background: darken(#e7e7e7, 5%);
          }

          &:not(:last-child) {
            margin-right: 5px;
          }

          &:not(.is-total) {
            &:after {
              content: "x";
              padding: 3px 0 0 5px;
              margin-left: 5px;
              border-left: 1px solid #d5d5d5;
            }
          }
        }
      }
    }

    .ui-select-icon {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);

      svg {
        position: absolute;
        top: 50%;
        height: 10px;
        transform: translateY(-50%);
        transition: 0.2s all;

        path {
          fill: #5d6373;
        }
      }
    }
  }

  .ui-select-wrap-block {
    position: relative;
  }

  .ui-select-dropdown {
    position: absolute;
    z-index: 1005;
    border-radius: 0 0 5px 5px;
    background: #fff;
    max-height: 0;
    left: -2px;
    top: 100%;
    width: calc(100% + 4px);
    overflow: hidden;

    &.is-showing {
      max-height: 500px;
      border-left: 2px solid #f6f7fb;
      border-right: 2px solid #f6f7fb;
      border-bottom: 2px solid #f6f7fb;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
      max-height: 170px;
      overflow: auto;
    }

    .ui-multiple-select-option {
      font-size: 14px;
      font-weight: 400;
      padding: 8px 18px;

      .ui-checkbox {
        width: 100%;
      }
    }

    .ui-select-option {
      font-size: 12px;
      font-weight: 400;
      color: #4e5c70;
      padding: 8px 18px;
      background: #fff;
      transition: 0.2s all;
      opacity: 0.7;

      &:not(.none-available) {
        font-size: 14px;
        color: #4e5c70;
        padding: 8px 18px;
        cursor: pointer;
        opacity: 1;

        &.is-active,
        &:hover {
          color: #fff;
          background: $primary;
        }
      }
    }
  }

  small {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 1em;
    margin: 5px 0 0;
    color: #a8afbb;
  }
}
