@import "./assets/styles/vars.scss";

#root {
  height: 100vh;
  overflow-y: auto;
}

.app {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 315px 1fr;

  &.with-bg {
    background: #edf0f4;
  }

  .app-page {
    display: flex;
    flex-direction: column;
    margin: 25px;
    background: #fff;
    overflow-y: auto;
    border-radius: 5px;
    padding: 30px 45px;
  }

  a {
    text-decoration: none;
  }

  .btn {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transition: 0.2s;
    white-space: nowrap;
    padding: 12px 26px;

    &.primary {
      background: $primary;
      border: 2px solid darken($primary, 7%);

      &:hover {
        background: darken($primary, 7%);
      }
    }

    &.secondary {
      background: $secondary;
      border: 2px solid darken($secondary, 7%);

      &:hover {
        background: darken($secondary, 7%);
      }
    }
  }

  .page-title {
    margin-bottom: 35px;

    .page-title-flex {
      display: flex;
      align-items: center;

      &.with-margin {
        margin-bottom: 10px;
      }

      h1 {
        margin: 0;
      }

      .button {
        margin-left: 20px;
      }

      .ui-select-wrapper {
        width: 190px;
        margin-left: 20px;
      }
    }

    h1 {
      font-size: 26px;
      font-weight: 600;
      margin: 0 0 18px;
      line-height: 1em;
    }

    p {
      &:not(.ui-select-placeholder) {
        max-width: 810px;
        font-size: 16px;
        font-weight: 300;
        padding: 20px 55px 20px 30px;
        border-radius: 4px;
        line-height: 1.5em;
        margin: 0;
        background: $backgroundGrey;
      }
    }
  }
}

@media (max-width: 1500px) {
  .app {
    grid-template-columns: 265px 1fr;

    .page-title {
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 15px;
        max-width: 640px;
      }
    }
  }
}
