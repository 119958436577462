@import "../../../assets/styles/vars.scss";

.add-meal-modal {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 190px 1fr;
  height: 100%;

  .add-meal-modal-inputs {
    height: 100%;
    overflow-y: auto;
    padding: 24px;

    .add-meal-field-group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 24px;
      opacity: 0;
      height: calc(100% - 24px);
      animation-name: slideInputsUp;
      animation-duration: 0.3s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;

      @keyframes slideInputsUp {
        from {
          margin-top: 24px;
          opacity: 0;
        }

        to {
          margin-top: 0;
          opacity: 1;
        }
      }
    }

    h1 {
      font-size: 22px;
      font-weight: 700;
      margin: 0 0 12px;
    }

    .column-group {
      max-height: 250px;
      overflow-y: auto;

      h2 {
        font-size: 14px;
        font-weight: 500;
        color: #4e5c70;
        margin: 0 0 10px;
      }

      .list-item {
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .input-group-column {
      display: grid;
      grid-gap: 20px;

      &.cols-2 {
        grid-template-columns: repeat(2, 1fr);

        &.weighted {
          grid-template-columns: 1fr 2fr;
        }
      }

      &.cols-3 {
        grid-template-columns: repeat(3, 1fr);
      }

      &.cols-4 {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    .input-block {
      width: 100%;

      textarea,
      input {
        width: 100%;
      }
    }

    .add-list-item {
      margin-bottom: 20px;

      p {
        color: $primary;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 0;
        line-height: 1em;

        svg {
          height: 16px;
          width: auto;
          margin-right: 8px;

          path {
            fill: $primary;
          }
        }
      }
    }

    .save-list-item {
      display: flex;
      align-items: center;

      p {
        margin: 0;
        line-height: 1em;
        color: $primary;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: 0.2s all;

        &.red {
          color: $red;

          &:hover {
            color: darken($red, 5%);
          }
        }

        &:hover {
          color: darken($primary, 5%);

          svg {
            path {
              fill: darken($primary, 5%);
            }
          }
        }

        &:not(:last-of-type) {
          margin-right: 32px;
        }

        svg {
          height: 12px;
          width: auto;
          margin-right: 3px;

          path {
            fill: $primary;
            transition: 0.2s all;
          }
        }
      }
    }

    .list-item {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      padding-right: 4px;

      span {
        cursor: pointer;

        &:hover {
          svg {
            transform: scale(1.1);
          }
        }

        svg {
          height: 15px;
          width: auto;
          margin-left: 20px;
          transition: 0.2s all;
          transform: scale(1);

          path {
            fill: $red;
          }
        }
      }
    }

    .instructions-seperator {
      height: 2px;
      width: calc(100% + 48px);
      background: #edf0f4;
      position: relative;
      left: -24px;
      margin: 20px 0;
    }

    .instructions-list {
      max-height: 120px;
      overflow-y: auto;

      .list-item {
        margin: 0;
        line-height: 1.4em;
        width: 100%;
        justify-content: space-between;

        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
      }
    }

    .tags-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &.summary {
        margin-bottom: 18px;

        p {
          padding: 4px 12px;
        }
      }

      p {
        position: relative;
        color: $subtitleGrey;
        font-size: 12px;
        font-weight: 500;
        border: 1px solid $subtitleGrey;
        border-radius: 20px;
        padding: 4px 35px 4px 10px;
        line-height: 1em;
        margin: 0 8px 8px 0;
        white-space: nowrap;
        user-select: none;

        span {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          border-left: 1px solid $subtitleGrey;
          background: #fff;
          border-radius: 0 20px 20px 0;
          transition: 0.2s all;
          cursor: pointer;

          &:hover {
            color: #fff;
            background: $red;
          }
        }
      }
    }
  }

  .add-meal-modal-nav {
    padding: 24px;
    border-right: 2px solid #edf0f4;

    p {
      display: flex;
      align-items: center;
      margin: 0;
      line-height: 1em;
      font-size: 15px;
      font-weight: 500;
      color: #a2a9b5;
      opacity: 0.5;
      transition: 0.2s all;

      &.is-active {
        color: $primary;
        cursor: pointer;
        opacity: 1;

        &:before {
          background: $primary;
        }
      }

      &.is-past {
        color: #4e5c70;
        cursor: pointer;
        opacity: 1;

        &:before {
          background: #4e5c70;
        }
      }

      &:before {
        content: "";
        height: 8px;
        width: 8px;
        flex: 8px 0 0;
        margin-right: 12px;
        background: #a2a9b5;
        border-radius: 100%;
        transition: 0.2s all;
      }

      &:not(:last-of-type) {
        margin-bottom: 18px;
      }
    }
  }
}
