@import "../../assets/styles/vars.scss";

.signup-flow {
  height: 100vh;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 2fr 7fr;

  .signup-flow-left {
    display: flex;
    flex-direction: column;
    color: #fff;
    background: $primary;
    padding: 50px;

    h2 {
      font-size: 22px;
      line-height: 1em;
      font-weight: 600;
      margin: 0 0 8px;
    }

    h1 {
      font-size: 56px;
      line-height: 1em;
      font-weight: 600;
      margin: 0;
    }

    .copyright {
      font-size: 14px;
      font-weight: 400;
      margin: auto 0 0;
      white-space: nowrap;
    }
  }

  .signup-flow-window {
    padding: 50px 110px;
    background: #f6f7fb;

    .title-tags {
      display: flex;
      margin-bottom: 70px;

      .signup-titles {
        h1 {
          margin: 0 0 6px;
          font-size: 26px;
          font-weight: 600;
        }

        p {
          margin: 0;
          font-size: 20px;
          color: $subtitleGrey;
        }
      }

      .need-help {
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 0 auto;
        color: $subtitleGrey;

        a {
          color: $primary;
          font-weight: 600;
          text-decoration: none;
          transition: 0.2s all;

          &:hover {
            color: darken($primary, 5%);
          }
        }
      }
    }
  }
}

.input-block-section {
  max-width: 650px;

  &:not(:last-child) {
    margin-bottom: 55px;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1em;
    margin-bottom: -2px;
  }

  p {
    font-size: 16px;
    line-height: 1.55em;
    max-width: 575px;
    color: #959da9;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: $primary;
    transition: 0.2s all;

    &:hover {
      color: darken($primary, 5%);
    }
  }

  .input-block,
  .rdt {
    width: 100%;
  }

  input {
    color: #4e5c70;
    background: #fff;
    border: 2px solid #50bac8 !important;
    box-shadow: 0 0 2px #50bac8;
    width: 100%;
  }

  .input-elements-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    margin-top: 28px;

    &.cols-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.signup-next-stage {
  margin-top: 55px;
}

@media (max-width: 1450px) {
  .signup-flow {
    .signup-flow-left {
      padding: 45px;

      h2 {
        font-size: 20px;
        margin: 0 0 8px;
      }

      h1 {
        font-size: 50px;
      }

      .copyright {
        font-size: 12px;
      }
    }

    .signup-flow-window {
      padding: 45px 85px;

      .title-tags {
        margin-bottom: 80px;

        .signup-titles {
          h1 {
            font-size: 24px;
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}
