@import "../../assets/styles/vars.scss";

.goals-wrapper {
  display: flex;

  .goals-divider {
    width: 2px;
    background: $accentGrey;
    margin: 0 60px;
  }

  .goal-circles-block {
    .circle-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 1em;
      margin-bottom: 32px;
    }

    .goal-circles {
      display: grid;
      grid-gap: 36px 40px;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;

      .no-goals-notice {
        grid-column-end: span 3;
        font-size: 15px;
        font-weight: 500;
        margin: 0;
        color: #7e838d;
      }

      .goal-circle {
        position: relative;
        text-align: center;

        &.personal {
          .goal-disc {
            .goal-progress {
              background: linear-gradient(0deg, $primary 50%, transparent 50%),
                linear-gradient(0deg, $primary 50%, #fff 50%);
            }
          }
        }

        &.trainer {
          .goal-disc {
            .goal-progress {
              background: linear-gradient(0deg, $secondary 50%, transparent 50%),
                linear-gradient(0deg, $secondary 50%, #fff 50%);
            }
          }
        }

        small {
          display: block;
          font-size: 14px;
          font-weight: 500;
          line-height: 1em;
          opacity: 0.65;
          color: $lightGreyBlue;
          margin-bottom: 10px;
        }

        svg {
          height: 125px;
          width: 125px;
          border-radius: 100%;
          box-shadow: 0 0 20px rgba(165, 173, 190, 0.48);
        }

        p {
          position: absolute;
          top: 39%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 5;
          font-size: 28px;
          color: $lightGreyBlue;
          opacity: 0.65;
          font-weight: 600;

          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.goal-checkboxes {
  margin-top: 55px;

  .goal-checkbox-list {
    .add-new-goal-checkbox {
      display: flex;

      .input-block {
        flex: 0 0 250px;
        max-width: 250px;
        width: 100%;
      }

      button {
        margin: auto 0 0 10px;
        height: 47px;
      }
    }
  }

  .goal-checkboxes-wrap {
    margin-top: 16px;
    max-width: 830px;
  }

  .goal-checkbox {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 10px 20px 1fr 2fr 55px;
    align-items: center;
    grid-gap: 12px;
    padding: 12px 20px;

    &:nth-child(odd) {
      background: $backgroundGrey;
    }

    p {
      margin: 0;
    }

    .gc-drag {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .gc-checkbox {
      .gc-inner-box {
        position: relative;
        height: 20px;
        width: 20px;
        background: #fff;
        border: 2px solid $primary;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          transform: scale(0.5);
          transition: 0.2s all;
          font-size: 14px;

          path {
            fill: #fff;
          }
        }

        &.checked {
          background: $primary;

          svg {
            transform: scale(1);
          }
        }
      }
    }

    .gc-title {
      p {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .gc-comments {
      &.hascomment {
        p {
          font-size: 13px;
          opacity: 1;
        }
      }

      p {
        color: $placeholderGrey;
        font-size: 12px;
        font-weight: 500;
        opacity: 0.5;
      }
    }

    .gc-remove {
      p {
        color: $red;
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}
