@import "../../assets/styles/vars.scss";

.navigation-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  padding: 50px 0 50px 55px;
  height: 100%;
  z-index: 1005;

  .navigation-profile {
    position: relative;
    margin-bottom: 65px;
    display: flex;
    align-items: center;

    .navigation-profile-image {
      position: relative;
      height: 64px;
      width: 64px;
      flex: 0 0 64px;
      border-radius: 50%;
      background: $secondary;
      margin-right: 25px;
      overflow: hidden;

      img {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .navigation-profile-initials {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 27px;
        color: #fff;
        line-height: 1em;
        font-weight: 500;
      }
    }

    .navigation-profile-extra {
      flex: 1 1;

      .profile-name {
        font-size: 22px;
        font-weight: 700;
        line-height: 1em;
        margin-bottom: 10px;
        margin-top: 0;
      }

      .profile-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100px;

        a {
          &:hover,
          &.active {
            .navigation-profile-action {
              svg {
                path {
                  fill: $primary;
                }
              }
            }
          }
        }

        .navigation-profile-action {
          position: relative;

          svg {
            display: block;
            fill: $lightGreyBlue;
            transition: 0.2s all;
            cursor: pointer;

            path {
              transition: 0.2s all;
            }
          }

          &.no-pointer {
            pointer-events: none;
          }

          &:hover {
            svg {
              path {
                fill: $primary;
              }
            }
          }

          &.has-unread {
            &:after {
              content: "";
              height: 8px;
              width: 8px;
              position: absolute;
              top: -5px;
              right: -5px;
              background: $negativeFG;
              border-radius: 100%;
              overflow: hidden;
              border: 2px solid #fff;
            }
          }

          &.active {
            svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }

  .nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .nav-item {
      &:not(:last-child) {
        margin-bottom: 28px;
      }

      &.inactive {
        opacity: 0.25;
        pointer-events: none;
      }

      a {
        display: flex;
        align-items: center;
        color: #000;
        transition: 0.2s all;

        &.active,
        &:hover {
          color: $primary;

          .nav-icon {
            svg {
              fill: $primary;
            }
          }
        }

        .nav-icon {
          flex: 0 0 18px;
          height: 18px;
          margin-right: 20px;
          transition: 0.2s all;

          svg {
            fill: #000;
            transition: 0.2s all;
          }
        }

        span {
          flex: 1 1 auto;
          font-size: 18px;
          font-weight: 400;
          line-height: 1em;
          margin: 0;
        }
      }
    }

    .nav-button {
      &.primary {
        margin-right: 15px;

        a {
          background: $primary;
          border: 2px solid darken($primary, 7%);

          &:hover {
            background: darken($primary, 7%);
          }
        }
      }

      &.secondary {
        a {
          background: $secondary;
          border: 2px solid darken($secondary, 7%);

          &:hover {
            background: darken($secondary, 7%);
          }
        }
      }

      a {
        display: block;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        width: 125px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
      }
    }
  }

  .navigation-footer {
    margin-top: auto;

    .nf-name {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 600;
      color: #7e838d;
      line-height: 1em;

      img {
        height: 26px;
        margin-right: 8px;
      }
    }

    .nf-copyright {
      font-size: 15px;
      font-weight: 300;
      color: #7e838d;
      line-height: 1.4em;
      opacity: 0.6;
      margin-top: 5px;
    }
  }

  .navigation-logout {
    font-size: 12px;
    margin: 0;
    font-weight: 600;
    color: #7e838d;
    margin-top: 2px;
    cursor: pointer;
  }
}

@media (max-width: 1500px) {
  .navigation-wrapper {
    padding: 35px 0 35px 35px;

    .navigation-profile {
      .navigation-profile-image {
        height: 50px;
        width: 50px;
        flex: 0 0 50px;
        .navigation-profile-initials {
          font-size: 22px;
        }
      }

      .navigation-profile-extra {
        .profile-name {
          font-size: 20px;
        }

        .profile-actions {
          width: 85px;

          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    .nav-list {
      .nav-item {
        a {
          span {
            font-size: 17px;
          }
        }
      }
    }

    .navigation-footer {
      .nf-copyright {
        font-size: 14px;
      }
    }
  }
}
