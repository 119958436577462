@import "../../../assets/styles/vars.scss";

.view-trainer-details {
  padding: 22px 24px;
  border-bottom: 2px solid #edf0f4;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 95px 1fr;

  .vt-profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 95px;
    width: 95px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 24px;
    background: $secondary;

    .profile-initials {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: 500;
      color: #fff;
    }

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .vt-classes {
    margin-top: 10px;

    p {
      font-size: 14px;
      font-weight: 500;
      color: $placeholderGrey;
      line-height: 1em;
      margin: 0 0 4px 0;

      &.block-heading {
        font-size: 12px;
        font-weight: 400;
        color: $subtitleGrey;
      }
    }
  }

  .vt-bio {
    p {
      font-size: 14px;
      font-weight: 500;
      color: $placeholderGrey;
      line-height: 1.4em;
      margin: 0;
    }
  }

  .vt-qualifications {
    margin-top: 10px;

    p {
      font-size: 14px;
      font-weight: 500;
      color: $placeholderGrey;
      line-height: 1em;
      margin: 0 0 4px 0;

      &.block-heading {
        font-size: 12px;
        font-weight: 400;
        color: $subtitleGrey;
      }
    }
  }

  .vt-tags {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;

    .trainer-tag {
      color: $subtitleGrey;
      font-size: 10px;
      font-weight: 600;
      border: 2px solid $subtitleGrey;
      border-radius: 20px;
      padding: 3px 8px;
      line-height: 1em;
      margin: 5px 5px 0 0;
      white-space: nowrap;
    }
  }
}

.no-tags {
  font-size: 10px;
  line-height: 1em;
  margin: 0;
  color: $subtitleGrey;
  opacity: 0.65;
  font-weight: 400;
  margin: 8px 0;
}

.message-trainer {
  padding: 18px 24px 22px;

  .message-sent {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }

  .input-block {
    margin-bottom: 18px;
  }

  .button {
    margin: 0 auto;
    padding: 0 36px;
  }
}
