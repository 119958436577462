@import "../../assets/styles/vars.scss";

.notifications-max-height {
  max-height: 405px;
  overflow-y: auto;
}

.notification-element {
  position: relative;
  transition: 0.2s all;
  border-bottom: 1px solid $accentGrey;
  cursor: pointer;
  overflow: hidden;

  &.unread {
    background: #f4f7fb;

    .notification-preview {
      .notification-text {
        .notification-message {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 8px;
            width: 8px;
            background: $red;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &:not(.unread) {
    opacity: 0.25;
  }

  .notification-preview {
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    padding: 22px;
    background: #f4f7fb;
    transition: 0.2s all;
    display: flex;

    &.active {
      left: -60px;
    }

    .notification-text {
      width: 100%;

      .notification-message {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.25em;
        margin: 0 0 8px;
        max-width: 92%;
      }

      .notification-timestamp {
        font-size: 12px;
        line-height: 1em;
        color: $subtitleGrey;
        margin: 0;
        max-width: 234px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-bottom: 2px;
      }
    }

    .notification-edit {
      position: absolute;
      right: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }
  }

  .notification-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    height: calc(100% - 2px);
    margin: 1px 0;
    width: 60px;
    top: 0;
    right: 0;
    color: #fff;

    .notification-action-delete {
      height: 100%;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $red;
      transition: 0.2s all;

      &:hover {
        background: darken($red, 5%);
      }
    }

    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.no-notifications {
  padding: 22px;
  font-size: 15px;
  color: $subtitleGrey;
  margin: 0;
}
