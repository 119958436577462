@import "../../assets/styles/vars.scss";

.parq-form {
  .parq-question {
    display: grid;
    grid-gap: 20px 85px;
    align-items: center;
    grid-template-columns: 495px 20px 20px;

    &:not(.headings) {
      padding-bottom: 20px;
    }

    &:not(:last-of-type) {
      &:not(.headings) {
        border-bottom: 1px solid $accentGrey;
        margin-bottom: 20px;
      }
    }

    &.headings {
      padding-bottom: 10px;
    }

    p {
      font-size: 14px;
    }
  }
}

.more-parq-details {
  margin-bottom: 20px;
  grid-column-end: span 3;

  .input-block,
  textarea {
    width: 100%;
  }
}
