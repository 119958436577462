$primary: #cd85de;
$primaryDark: #b56fc5;

$secondary: #50bac8;
$secondaryDark: #50bac8;

$green02: #50c968;
$blue02: #50bbc9;

$lightGreyBlue: #a5adbe;
$darkgrey: #545454;

$backgroundGrey: #f4f6f9;

$green: #30cba2;
$orange: #f77c4f;
$red: #ee5352;
$blue: #226cff;

$defaultBG: #e7e7e7;
$defaultFG: #616161;
$positiveBG: #d3f4d5;
$positiveFG: #47d764;
$amberBG: #fff3d5;
$amberFG: #ffc021;
$negativeBG: #fcdbdb;
$negativeFG: #d3423d;
$infoBG: #d5e8ff;
$infoFG: #2a66bf;
$disabledBG: #e5e7e9;
$disabledFG: #8c8b97;

$placeholderGrey: #4e5c70;
$placeholderGreyLight: #a2a9b5;

$accentGrey: #edf0f4;
$accentGreyLight: #f8f9fb;

$subtitleGrey: #959da9;

$gradient: linear-gradient(225deg, $primary, $secondary);
