@import "../../assets/styles/vars.scss";

.account-edit-title {
  margin: 0 0 14px;

  h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    line-height: 1em;
  }
}

.account-navigation {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #edf0f4;
  padding-bottom: 5px;
  margin-bottom: 35px;

  p {
    position: relative;
    color: #4e5c70;
    font-size: 15px;
    font-weight: 500;
    line-height: 1em;
    margin: 0;
    cursor: pointer;
    transition: 0.2s all;

    &:not(:last-of-type) {
      margin-right: 32px;
    }

    &:after {
      content: "";
      height: 2px;
      width: 100%;
      background: $primary;
      position: absolute;
      left: 0;
      bottom: -7px;
      opacity: 0;
      transition: 0.2s all;
    }

    &:hover,
    &.is-active {
      color: $primary;

      &:after {
        opacity: 1;
      }
    }
  }
}

.column-field-group {
  display: grid;
  grid-gap: 15px;

  &.cols-1 {
    grid-template-columns: 1fr;
  }

  &.cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.edit-account-column {
  max-width: 650px;
}

.column-title {
  font-size: 22px;
  line-height: 1em;
  font-weight: 600;
  margin: 0 0 26px;

  &:not(:first-of-type) {
    margin-top: 65px;
  }
}

.save-button {
  margin-top: 50px;
}

.add-card-modal {
  .add-card-terms {
    p {
      color: $placeholderGrey;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.45em;
      background: $backgroundGrey;
      border-radius: 5px;
      padding: 18px 22px;
      margin: 0;

      span {
        font-weight: 600;
      }
    }
  }

  label {
    margin-top: 15px;
  }

  button {
    margin: 20px auto 0;
  }
}

.change-bank-details {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #4e5c70;
  margin: 25px 0 0;
}

.billing-label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #4e5c70;
  margin-bottom: 5px;
}

.no-billing-details {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: $placeholderGrey;
  opacity: 0.5;
}

.account-card {
  display: flex;
  align-items: center;
  background: #fff;
  width: 330px;
  padding: 12px 18px;
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0 0 10px rgba(68, 68, 68, 0.15);

  .card-logo {
    position: relative;
    height: 38px;
    width: 75px;
    border-radius: 2px;
    border: 2px solid #e5e5e5;
    margin-right: 20px;
    padding: 6px 10px;

    img {
      width: 100%;
    }
  }

  .card-details {
    .card-number {
      font-size: 15px;
      font-weight: 600;
      line-height: 1em;
      margin: 0 0 4px;
      color: $placeholderGrey;
    }

    .card-expiry {
      font-size: 12px;
      font-weight: 400;
      line-height: 1em;
      margin: 0;
      color: $placeholderGreyLight;
    }
  }

  .card-remove {
    color: $red;
    font-size: 13px;
    font-weight: 500;
    line-height: 1em;
    margin: 0 0 0 auto;
    cursor: pointer;
  }
}

.trainer-tags-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.summary {
    margin-bottom: 18px;

    p {
      padding: 4px 12px;
    }
  }

  p {
    position: relative;
    color: $subtitleGrey;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid $subtitleGrey;
    border-radius: 20px;
    padding: 4px 35px 4px 10px;
    line-height: 1em;
    margin: 0 8px 8px 0;
    white-space: nowrap;
    user-select: none;

    span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      border-left: 1px solid $subtitleGrey;
      background: #fff;
      border-radius: 0 20px 20px 0;
      transition: 0.2s all;
      cursor: pointer;

      &:hover {
        color: #fff;
        background: $red;
      }
    }
  }
}

.add-payment-method {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 1em;
  color: $primary;
  margin: 18px 0 0 0;
  width: fit-content;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
}
