.meals-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(5, 1fr);

  .meal-card {
    button {
      margin: 0 auto;
    }
  }
}

@media (max-width: 1650px) {
  .meals-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
