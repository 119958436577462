.metric-record-inputs {
  .recording-for {
    margin: 20px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.55em;
    text-align: center;
  }

  button {
    width: 100%;
  }
}
