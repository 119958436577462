@import "../../../assets/styles/vars.scss";

.input-block {
  position: relative;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.span-2 {
    grid-column-end: span 2;
  }

  .rdt,
  .PhoneInput {
    width: 100%;

    &.has-value {
      input {
        border: 2px solid $secondary;
      }
    }
  }

  label {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #4e5c70;
    margin-bottom: 4px;

    &.checkbox-label {
      cursor: pointer;
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 0;
    color: #4e5c70;
    opacity: 0.6;
  }

  &.fw {
    width: 100%;

    textarea,
    input,
    .rdt {
      width: 100%;
    }
  }

  .inputfield-wrapper {
    width: 100%;
    position: relative;

    .inputfield-icon {
      position: absolute;
      z-index: 2;
      top: 2px;
      left: 2px;
      bottom: 2px;
      color: #a8afbb;
      background: #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      width: 40px;

      svg {
        height: 17px;
        width: auto;

        path {
          fill: #a8afbb;
        }
      }
    }
  }

  textarea,
  input {
    position: relative;
    width: 340px;
    font-size: 15px;
    background: #fff;
    color: #4e5c70;
    transition: 0.2s all;
    outline: none !important;
    border-radius: 2px;
    padding: 10px 16px;
    resize: none;
    font-family: "Poppins", sans-serif;
    border: 2px solid #fff;

    &.has-icon {
      padding: 10px 16px 10px 55px;
    }

    &::placeholder {
      font-size: 14px;
      color: #a8afbb;
      transition: 0.2s all;
    }

    &.has-value {
      border: 2px solid $secondary;
    }

    &:placeholder-shown {
      color: #a8afbb;
      background: #f6f7fb;
      border: 2px solid #f6f7fb;
    }

    &:focus,
    &:active {
      color: #4e5c70;
      background: #fff;
      border: 2px solid #50bac8 !important;
      box-shadow: 0 0 2px #50bac8;
    }

    &:hover {
      color: #4e5c70;
      background: #fff;
      border: 2px solid $secondary;

      &::placeholder {
        color: #4e5c70;
      }
    }
  }

  &.valid {
    textarea,
    input {
      background: #fff;
      border: 2px solid $green;

      &:hover {
        box-shadow: 0 0 2px $green;
      }
    }
  }

  &.invalid {
    textarea,
    input {
      color: $red;
      background: #fff;
      border: 2px solid $red;

      &:hover {
        box-shadow: 0 0 2px $red;
      }
    }

    label {
      color: $red;
    }

    p {
      color: $red;
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    .checkbox-box {
      position: relative;
      height: 20px;
      width: 20px;
      background: #fff;
      border: 2px solid $primary;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        transform: scale(0.5);
        transition: 0.2s all;
        font-size: 14px;

        path {
          fill: #fff;
        }
      }

      &.checked {
        background: $primary;

        svg {
          transform: scale(1);
        }
      }
    }
  }
}

.input-grid {
  display: grid;
  grid-gap: 15px;

  &.cols2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.cols3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
