@import "../../assets/styles/vars.scss";

.section-title {
  font-size: 22px;
  font-weight: 600;
  margin: 45px 0 18px;
  line-height: 1em;

  &.no-top-pad {
    margin: 0 0 18px;
  }
}

.dashboard-calendar {
  .rbc-calendar {
    .rbc-toolbar {
      display: none !important;
    }
  }
}

.dashboard-cards {
  .dashboard-cards-grid {
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(3, 1fr);
  }

  .dashboard-card {
    position: relative;

    &:hover {
      &:after {
        opacity: 1;
      }

      .dashboard-card-inner {
        border: 5px solid $primary;

        svg {
          path {
            fill: $primary;
          }
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $gradient;
      opacity: 0;
      transition: 0.2s all;
      filter: blur(25px);
    }

    .dashboard-card-inner {
      position: relative;
      z-index: 2;
      padding: 24px 20px;
      border-radius: 4px;
      background: #fff;
      border: 5px solid $accentGrey;
      transition: 0.2s all;
      text-align: center;

      svg {
        margin: auto;
        height: 55px;
        width: 55px;

        path {
          fill: $placeholderGrey;
          transition: 0.2s all;
        }
      }

      h1 {
        font-size: 18px;
        font-weight: 600;
        color: $placeholderGrey;
        margin: 16px 0 0 0;
        line-height: 1em;
        text-align: center;
      }
    }
  }
}
