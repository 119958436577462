@import "../../../assets/styles/vars.scss";

.ui-card {
  position: relative;
  padding: 32px 10px;
  border-radius: 4px;
  background: #fff;
  border: 5px solid $accentGrey;
  transition: 0.2s all;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-width: 280px;

  &:hover {
    box-shadow: 0 0 35px rgb(216, 219, 223);
  }

  &.is-loading {
    .ui-card-image {
      img,
      .profile-initials {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  .ui-card-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    overflow: hidden;
    background: $secondary;
    margin: 0 auto 20px;
    transition: 0.2s;

    .profile-initials {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-transform: uppercase;
      font-size: 26px;
      font-weight: 500;
    }

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .ui-card-title {
    margin: 0 0 18px;

    p {
      font-size: 20px;
      font-weight: 600;
      color: $placeholderGrey;
      margin: 0;
      line-height: 1.25em;
    }

    small {
      font-size: 12px;
      color: $subtitleGrey;
    }
  }

  button {
    margin: auto auto 0;
  }
}
